<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.vehiclesClassesByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="vehicleClassesHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="vehicleClasses"
            :search="searchVehicleClassString"
            :item-class="addCustomClass"
            class="elevation-1"
            @click:row="editVehicleClassItem"
            multi-sort>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.vehiclesClasses') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchVehicleClassString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer></v-spacer>

                <v-dialog v-model="vehicleClassDialog" max-width="700px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('buttons.add') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(vehicleClassFormTitle) }}
                      <v-spacer></v-spacer>
                      <v-icon class="mdi mdi-close" style="color: white" @click="closeVehicleClassEdit"></v-icon>
                    </v-card-title>

                    <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                      <v-form lazy-validation @submit.prevent="handleSubmit(saveVehicleClass)">
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" hidden md="6" sm="12">
                                <v-text-field
                                  v-model="editedVehicleClassItem.id"
                                  :label="$t('vehicleClassesPage.id')"></v-text-field>
                              </v-col>
                              <v-col cols="12" md="12" sm="12">
                                <validation-provider rules="required|max:200" v-slot="{ errors }" name="vehicle_class">
                                  <v-text-field
                                    v-model="editedVehicleClassItem.name"
                                    autofocus
                                    clearable
                                    counter="200"
                                    :label="$t('vehicleClassesPage.vehicleClass')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                        </v-card-actions>
                      </v-form>
                    </validation-observer>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="vehicleClassDialogDelete" max-width="650">
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ editedVehicleClassItem.name }}
                      <v-spacer></v-spacer>
                      <v-icon class="mdi mdi-close" style="color: white" @click="closeVehicleClassDelete"></v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <p>
                          {{ $t('vehicleClassesPage.deleteMessage') }}
                        </p>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :disabled="!enableVehicleClassSave"
                        class="primary"
                        text
                        @click="deleteVehicleClassItemConfirm">
                        {{ $t('buttons.yes') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn }">
                      <v-btn text v-on="{ ...on, ...tooltipOn }">
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('buttons.actions') }}
                    </span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="editVehicleClassItem(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.edit') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.edit') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="makeVehicleClassActive(item)">
                        <v-icon v-if="item.active" class="mx-1" small v-bind="attrs">mdi-toggle-switch-outline</v-icon>
                        <v-icon v-if="!item.active" class="mx-1" small v-bind="attrs"
                          >mdi-toggle-switch-off-outline</v-icon
                        >
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ item.active ? $t('buttons.deactivate') : $t('buttons.activate') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ item.active ? $t('tooltips.deactivate') : $t('tooltips.activate') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="deleteItem(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.delete') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.delete') }}</span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { vehicleClassesHeaders } from '@/mixins/data-table-headers';
import { defaultVehicleClass } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';

export default {
  name: 'VehicleClassPage',
  components: { ButtonSubmit },
  data: () => ({
    searchVehicleClassString: '',
    vehicleClassDialog: false,
    vehicleClassDialogDelete: false,
    vehicleClasses: [],
    vehicleClassFormTitle: 'vehicleClassesPage.newItem',
    vehicleClassFormValid: true,
    editedVehicleClassItem: {},
    enableVehicleClassSave: true,
    loading: false,
  }),
  created() {
    this.editedVehicleClassItem = Object.assign({}, defaultVehicleClass);
    this.loadAllVehicleClasses();
  },
  computed: {
    vehicleClassesHeaders() {
      return vehicleClassesHeaders(i18n);
    },
  },
  methods: {
    async loadAllVehicleClasses() {
      await this.$store.dispatch('vehicleClasses/getAllVehicleClasses').then((res) => {
        this.vehicleClasses = res.data.map((e) => {
          e.active = parseInt(e.active);
          return e;
        });
      });
    },

    editVehicleClassItem(item) {
      this.editedVehicleClassItem = this.vehicleClasses.indexOf(item);
      this.editedVehicleClassItem = Object.assign({}, item);
      this.vehicleClassFormTitle = item.name;
      this.vehicleClassDialog = true;
    },

    deleteItem(item) {
      this.editedVehicleClassItem = this.vehicleClasses.indexOf(item);
      this.editedVehicleClassItem = Object.assign({}, item);
      this.vehicleClassDialogDelete = true;
    },

    async saveVehicleClass() {
      this.enableVehicleClassSave = false;
      this.loading = true;
      let path = 'saveVehicleClass';

      if (this.editedVehicleClassItem.id) {
        Object.assign(this.editedVehicleClassItem, { _method: 'POST' });
        path = 'updateVehicleClass';
      }

      await this.$store
        .dispatch('vehicleClasses/' + path, this.editedVehicleClassItem)
        .then(() => {
          this.loadAllVehicleClasses();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.closeVehicleClassEdit();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enableVehicleClassSave = true;
          this.loading = false;
          this.vehicleClassFormTitle = 'vehicleClassesPage.newItem';
        });
    },

    async deleteVehicleClassItemConfirm() {
      await this.$store
        .dispatch('vehicleClasses/deleteVehicleClass', this.editedVehicleClassItem)
        .then(() => {
          this.loadAllVehicleClasses();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'red' });
          this.closeVehicleClassDelete();
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.closeVehicleClassDelete();
        });
    },

    closeVehicleClassEdit() {
      this.vehicleClassDialog = false;
      this.$nextTick(() => {
        this.editedVehicleClassItem = Object.assign({}, defaultVehicleClass);
      });
      this.$refs.form.reset();
      this.vehicleClassFormTitle = 'vehicleClassesPage.newItem';
    },

    closeVehicleClassDelete() {
      this.vehicleClassDialogDelete = false;
      this.$nextTick(() => {
        this.editedVehicleClassItem = Object.assign({}, defaultVehicleClass);
      });
    },

    async makeVehicleClassActive(item) {
      await this.$store
        .dispatch('vehicleClasses/changeVehicleClassActiveStatus', item)
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.activeStatusChanged'), color: 'green' });
          item.active = !item.active;
        })
        .catch(() => {
          item.active = !item.active;
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },

    addCustomClass(item) {
      return item.active === true || item.active == 1 ? 'active-row' : 'inactive-row';
    },
  },

  watch: {
    vehicleClassDialog(val) {
      val || this.closeVehicleClassEdit();
    },
  },
};
</script>
